import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Stack,
  Carousel,
} from "react-bootstrap";
import "../insights/Insights.scss";
import CustNavbar from "../navbar/CustNavbar";
import { ReactLenis, useLenis } from "lenis/react";
import icon from "../assets/iconwithtxt.png";
import { Link } from "react-router-dom";
import Image1 from "../assets/event img.png";
import axios from "axios";
import Footer from "../footer/Footer";

function Insights() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [jsonData, setJsonData] = useState({"blogs":[]});
  const handleNext = (length, a) => {
    a((prevIndex) => (prevIndex + 1) % length);
  };

  const handlePrev = (length, a) => {
    a((prevIndex) => (prevIndex - 1 + length) % length);
  };

    // useEffect(() => {
    //   axios
    //     .get("https://actionproc.rf.gd/api/blogContent/getAllBlogs/")
    //     .then((res) => {
    //       setJsonData(res.data);
    //     });
    // });
  return (
    <ReactLenis root>
      <div className="main">
        <CustNavbar
          className="nav"
          style={{
            backgroundColor: "#ffffff",
            color: "#000000",
            backgroundOpacity: "1",
          }}
        />
        <div className="events">
          <div className="eventsTitle">Events.</div>
          <div
            className="eventsTiles"
            style={{
              transform: `translateX(-${
                currentIndex * document.getElementById("card0")?.clientWidth
              }px)`,
            }}
          >
            {[...Array(2)].map((x, i) => (
              <div
                className={"eventCard " + "card" + i}
                id={"card" + i}
                key={i}
              >
                <div className="img">
                  <img src={Image1} />
                </div>
                <div className="eventCardText ">
                  <h4>CardPaymentz Seminar {i}</h4>
                  <div className="datetime">Dec 15, 2024 - Dec 16, 2024</div>
                  <p>
                    The event offers a calm and private setting to network with
                    senior colleagues, learn from key members of the payments
                    community, and connect with leading solut
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="slideButtons">
            <Button
              className="slideButton"
              onClick={() => handlePrev(10, setCurrentIndex)}
            >
              <svg
                fill="#ffffff"
                height="20px"
                width="20px"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 330 330"
                xmlSpace="preserve"
                transform="rotate(180)"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    id="XMLID_27_"
                    d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255 s7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0 c-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z"
                  ></path>{" "}
                </g>
              </svg>
            </Button>
            <Button
              className="slideButton"
              onClick={() => handleNext(10, setCurrentIndex)}
            >
              <svg
                fill="#ffffff"
                height="20px"
                width="20px"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 330 330"
                xmlSpace="preserve"
                stroke="#ffffff"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    id="XMLID_27_"
                    d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255 s7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0 c-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z"
                  ></path>{" "}
                </g>
              </svg>
            </Button>
          </div>
        </div>
        <div className="blogs">
          <div className="blogTitle">Blogs.</div>
          <div className="blogTiles">
            <div className="events">
              <div
                className="eventsTiles"
                style={{
                  transform: `translateX(-${
                    currentIndex * document.getElementById("card0")?.clientWidth
                  }px)`,
                }}
              >
                {[...Array(2)].map((x, i) => {
                return(
          
                  <div
                    className={"eventCard " + "card" + i}
                    id={"card" + i}
                    key={i}
                  >
                    <div className="img">
                      <img src={Image1} />
                    </div>
                    <div className="eventCardText ">
                      <h4>CardPaymentz Seminar {i}</h4>
                      <div className="datetime">
                        Dec 15, 2024 - Dec 16, 2024
                      </div>
                      <p>
                        The event offers a calm and private setting to network
                        with senior colleagues, learn from key members of the
                        payments community, and connect with leading solut
                      </p>
                    </div>
                  </div>
                );})}
              </div>
              <div className="slideButtons">
                <Button
                  className="slideButton"
                  onClick={() => handlePrev(10, setCurrentIndex)}
                >
                  <svg
                    fill="#ffffff"
                    height="20px"
                    width="20px"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 330 330"
                    xmlSpace="preserve"
                    transform="rotate(180)"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        id="XMLID_27_"
                        d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255 s7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0 c-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z"
                      ></path>{" "}
                    </g>
                  </svg>
                </Button>
                <Button
                  className="slideButton"
                  onClick={() => handleNext(10, setCurrentIndex)}
                >
                  <svg
                    fill="#ffffff"
                    height="20px"
                    width="20px"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 330 330"
                    xmlSpace="preserve"
                    stroke="#ffffff"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        id="XMLID_27_"
                        d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255 s7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0 c-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z"
                      ></path>{" "}
                    </g>
                  </svg>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Footer  style={{ borderRadius: "64px" }} />
      </div>
    </ReactLenis>
  );
}
export default Insights;
