import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../contact/Contact.scss";
import CustNavbar from "../navbar/CustNavbar";
import { ReactLenis, useLenis } from "lenis/react";

function Contact() {
  return (
    <ReactLenis root>
      <div className="main">
        <CustNavbar
          className="nav"
          style={{
            backgroundColor: "#ffffff",
            color: "#000000",
            backgroundOpacity: "1",
          }}
        />
        <div className="contactus">
          <h1 className="contactHeader">Contact Us</h1>
          <Container fluid className="contactSection">
            <Row className="sectionRow">
              <Col md={6} sm={12} className="subsection subleft">
                <div className="leftsection">
                  We would love to work with you!
                </div>
                <div className="contactinfo">
                  <p>
                    27 Old Gloucester Street, London, United Kingdom, WC1N 3AX
                  </p>
                  <p>
                    <a href="mailto:processing@cardpaymentz.com">
                      processing@cardpaymentz.com
                    </a>
                  </p>
                  <p className="tel">
                    <a href="tel:+44-333-339-4167">+44-333-339-4167</a>
                  </p>
                  <h3>For any Questions</h3>
                </div>
              </Col>
              <Col md={6} sm={12} className="subsection subright">
                <div className="rightsection">
                  <h2>We are very close to each other...</h2>
                  <h4>
                    All that's left to do is fill out the form to partner with
                    us
                  </h4>
                  <div className="form">
                    <div className="formpart">
                      <input
                        placeholder="Name"
                        style={{ width: "46%" }}
                      ></input>
                      <input
                        placeholder="Company"
                        style={{ width: "46%" }}
                      ></input>
                    </div>
                    <div className="formpart">
                      <input
                        placeholder="Phone"
                        style={{ width: "46%" }}
                      ></input>
                      <input
                        placeholder="Email"
                        style={{ width: "46%" }}
                      ></input>
                    </div>
                    <textarea
                      placeholder="Message"
                      type="text"
                      style={{ width: "100%" }}
                      className="messageIp"
                    ></textarea>

                    <Button>Submit</Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </ReactLenis>
  );
}

export default Contact;
