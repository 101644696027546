import { useCallback, useState, useEffect, useRef, createRef } from "react";
import { useLocation } from "react-router-dom";
import "../blogs/Blogs.scss";
import axios from "axios";
function Blogs() {
	const [jsonResponse, setJsonResponse] = useState({});
	const [loading, setLoading] = useState(true); // State to manage loading status
	const [error, setError] = useState(null); // State to manage errors
	const query = new URLSearchParams(useLocation().search);
	const id = query.get("id");
	useEffect(() => {
		axios
			.get(
				"https://actionproc.rf.gd/api/blogContent/getAllBlogs/",
				{
					id: parseInt(id),
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
			.then((response) => {
				setJsonResponse(response.data);
				setLoading(false);
			})
			.catch((error) => {
				setError(error);
			});
	}, []);
	if (loading) {
		return <div>Loading...</div>;
	}

	if (error) {
		return <div>Error: {error}</div>;
	}
	return (
		<div>
			{jsonResponse?.blogs
				.filter((blog) => blog.blog_id === Number(id))
				.map((blog) => (
					<div className="blogDiv">
						<div className="imageDiv">
							<img src={blog.image_url} alt="NA"></img>
						</div>
						<h1 className="blogHeading">{blog.blog_header}</h1>
						<h6>{blog.blog_mini_desc}</h6>
						<p className="blogContent">{blog.blog_text}</p>
					</div>
				))}
		</div>
	);
}

export default Blogs;
